// Importing all resources
import "../home.css";
import "../montessori.css";
import "../responsive.css";
import "../creche.css";
import hero from "../assets/montessoriIntro.png";
import circles from "../assets/cirlces.png";
import momentoneImg from "../assets/montessoriMomentOne.png";
import momenttwoImg from "../assets/montessoriMomentTwo.png";
import momentThreeImg from "../assets/montessoriMomentThree.png";
import momentFourImg from "../assets/montessoriMomentFour.png";
import momentFiveImg from "../assets/montessoriMomentFive.png";
import Footer from "../components/footer";
import star from "../assets/star.svg";
import curious from "../assets/curious.svg";
import hat from "../assets/hat.svg";
import overviewImg from "../assets/montessoriProgramOverview.png";
import Button from "../components/button";
import NavBar from "../components/navbar";
import Navbartrial from "../components/navbartrial";
import { useEffect } from "react";

// Intro page Section / Component
const IntroPage = () => {
  return (
    <>
      <article className="programsPage intro">
        <article>
          <h2>Embrace Lifelong Learning with Creche</h2>
          <p>Nurturing Curiosity, Independence, and Creativity</p>
          <a href="/contact">
            <Button value="Schedule a Visit" />
          </a>
        </article>
        <article className="heroArticle toBeHidden">
          <img src={hero} alt="Three students" />
          <img src={circles} alt="Three students" className="circles" />
        </article>
        <img src={hero} alt="A little girl painting" className="hide" />
      </article>
    </>
  );
};

// Simple Card Component
const SimpleCard = (props) => {
  const cardsArray = [
    {
      heading: "Exploring Little Minds",
      imageSrc: star,
      imageAltText: "A star icon",
      text: "The Exploring Little Minds curriculum is designed for infants aged 6 months to 1 year..",
      moreText:
        " We understand the importance of forming secure attachments during this stage, so our primary focus is to provide a warm and loving environment. Our experienced caregivers engage in responsive interactions to meet the unique needs of each child, promoting trust and emotional development.",
      id: "one",
    },
    {
      heading: "Curious Explorers",
      imageSrc: curious,
      imageAltText: "The icon of magnifying glasses",
      text: "The Curious Explorers curriculum is tailored for children aged 1 to 2 years, a phase marked",
      moreText:
        " by rapid exploration and discovery. We create an environment that encourages curiosity and hands-on learning to stimulate cognitive growth and motor skills. Our teachers facilitate learning through play, ensuring each child's unique pace of development is respected and supported.",
      id: "two",
    },
    {
      heading: "Ready for Pre-K",
      imageSrc: hat,
      imageAltText: "A graduation hat icon",
      text: "The Ready for Pre-K curriculum is designed to prepare children aged 3 to 4 years for the...",
      moreText:
        " next stage of their education. We focus on building essential pre-academic skills while nurturing their emotional and social intelligence. Our program aims to instill confidence and independence, setting a strong foundation for a successful transition to formal schooling.",
      id: "three",
    },
  ];
  const targetObject = cardsArray.find((item) => item.heading === props.value);
  let hidden = true;
  function showMore(e) {
    e.preventDefault();
    if (hidden) {
      document
        .querySelector(`.simpleCard.${targetObject.id} .hide`)
        .classList.toggle("hide");
      hidden = false;
    } else {
      document
        .querySelector(`.simpleCard.${targetObject.id} span`)
        .classList.toggle("hide");
      hidden = true;
    }
  }
  return (
    <>
      <article className={`simpleCard ${targetObject.id}`}>
        <img src={targetObject.imageSrc} alt={targetObject.imageAltText} />
        <article className="content">
          <h3>{targetObject.heading}</h3>
          <p>
            {targetObject.text}
            <span className="hide">{targetObject.moreText}</span>
          </p>
          <br />
          <a href="#" onClick={showMore}>
            Read More...
          </a>
        </article>
      </article>
    </>
  );
};

// Curriculum Section / Component
const Curriculum = () => {
  return (
    <>
      <article className="curriculum">
        <p className="descText">Curriculum and Approach</p>
        <h2>Nurturing Well-Rounded Development</h2>
        <p>
          Our Creche curriculum fosters holistic growth, providing children with
          a rich educational experience that encompasses various subjects and
          areas of development.
        </p>
        <article className="simpleCards">
          <SimpleCard value="Exploring Little Minds" />
          <SimpleCard value="Curious Explorers" />
          <SimpleCard value="Ready for Pre-K" />
        </article>
      </article>
    </>
  );
};

// Fourth Section / Inquiry Section
const InquirySection = () => {
  return (
    <>
      <article className="inquirySection">
        <article className="content">
          <h3>Your Child's Future Starts Here</h3>
          <p>Where Education Meets Inspiration and Endless Possibilities</p>
          <a href="/admission">
            <button className="pinkBtn">Inquire Today</button>
          </a>
        </article>
      </article>
    </>
  );
};

// Program Overview Section / Component
const ProgramOverview = () => {
  useEffect(() => {
    const carousel = document.querySelector(".carousel"),
      firstImg = carousel.querySelectorAll(".horizontalCard")[0],
      arrowIcons = document.querySelectorAll(".wrapper i");

    let isDragStart = false,
      isDragging = false,
      prevPageX,
      prevScrollLeft,
      positionDiff;

    const showHideIcons = () => {
      // showing and hiding prev/next icon according to carousel scroll left value
      let scrollWidth = carousel.scrollWidth - carousel.clientWidth; // getting max scrollable width
      arrowIcons[0].style.display = carousel.scrollLeft == 0 ? "none" : "block";
      arrowIcons[1].style.display =
        carousel.scrollLeft == scrollWidth ? "none" : "block";
      if (carousel.clientWidth <= 550) {
        arrowIcons[0].style.display =
          carousel.scrollLeft == 0 ? "none" : "none";
        arrowIcons[1].style.display =
          carousel.scrollLeft == scrollWidth ? "none" : "none";
      }
    };

    arrowIcons.forEach((icon) => {
      icon.addEventListener("click", () => {
        let firstImgWidth = firstImg.clientWidth + 14; // getting first img width & adding 14 margin value
        // if clicked icon is left, reduce width value from the carousel scroll left else add to it
        carousel.scrollLeft +=
          icon.id == "left" ? -firstImgWidth : firstImgWidth;
        setTimeout(() => showHideIcons(), 60); // calling showHideIcons after 60ms
      });
    });

    const autoSlide = () => {
      // if there is no image left to scroll then return from here
      if (
        carousel.scrollLeft - (carousel.scrollWidth - carousel.clientWidth) >
          -1 ||
        carousel.scrollLeft <= 0
      )
        return;

      positionDiff = Math.abs(positionDiff); // making positionDiff value to positive
      let firstImgWidth = firstImg.clientWidth + 14;
      // getting difference value that needs to add or reduce from carousel left to take middle img center
      let valDifference = firstImgWidth - positionDiff;

      if (carousel.scrollLeft > prevScrollLeft) {
        // if user is scrolling to the right
        return (carousel.scrollLeft +=
          positionDiff > firstImgWidth / 3 ? valDifference : -positionDiff);
      }
      // if user is scrolling to the left
      carousel.scrollLeft -=
        positionDiff > firstImgWidth / 3 ? valDifference : -positionDiff;
    };

    const dragStart = (e) => {
      // updatating global variables value on mouse down event
      isDragStart = true;
      prevPageX = e.pageX || e.touches[0].pageX;
      prevScrollLeft = carousel.scrollLeft;
    };

    const dragging = (e) => {
      // scrolling images/carousel to left according to mouse pointer
      if (!isDragStart) return;
      e.preventDefault();
      isDragging = true;
      carousel.classList.add("dragging");
      positionDiff = (e.pageX || e.touches[0].pageX) - prevPageX;
      carousel.scrollLeft = prevScrollLeft - positionDiff;
      showHideIcons();
    };

    const dragStop = () => {
      isDragStart = false;
      carousel.classList.remove("dragging");

      if (!isDragging) return;
      isDragging = false;
      autoSlide();
    };

    carousel.addEventListener("mousedown", dragStart);
    carousel.addEventListener("touchstart", dragStart);

    document.addEventListener("mousemove", dragging);
    carousel.addEventListener("touchmove", dragging);

    document.addEventListener("mouseup", dragStop);
    carousel.addEventListener("touchend", dragStop);
  }, []);
  return (
    <>
      <article className="programOverview">
        <p className="descText">Program Overview</p>
        <h2>A Journey of Discovery and Growth</h2>
        <p>
          Our Montessori Program is designed for children aged 3 to 6 years old,
          providing a transformative educational experience that lays the
          founation for lifelong learning.
        </p>
        <article className="wrapper">
          <i id="left" className="fa-solid fa-angle-left toBeHidden"></i>
          <div className="carousel">
            <article className="horizontalCard">
              <img
                src={overviewImg}
                alt="A lady and two little girls staring at a drawing while one of the girls is doing the drawing"
              />
              <article>
                <h3>Nurturing Independent Thinkers</h3>
                <p>
                  At RPMS, we follow the authentic Montessori approach,
                  empowering children to become independent thinkers and problem
                  solvers. Our program fosters a deep love for learning,
                  cultivates curiosity, and nurtures a sense of self-discipline.
                </p>
                <a href="/admissionform">
                  <Button value="Enroll Now" />
                </a>
                {/* I should but the carousel buttons */}
              </article>
            </article>
            <article className="horizontalCard toBeHidden">
              <img
                src={overviewImg}
                alt="A lady and two little girls staring at a drawing while one of the girls is doing the drawing"
              />
              <article>
                <h3>Nurturing Independent Thinkers</h3>
                <p>
                  At RPMS, we follow the authentic Montessori approach,
                  empowering children to become independent thinkers and problem
                  solvers. Our program fosters a deep love for learning,
                  cultivates curiosity, and nurtures a sense of self-discipline.
                </p>
                <a href="/admissionform">
                  <Button value="Enroll Now" />
                </a>
                {/* I should but the carousel buttons */}
              </article>
            </article>
            <article className="horizontalCard toBeHidden">
              <img
                src={overviewImg}
                alt="A lady and two little girls staring at a drawing while one of the girls is doing the drawing"
              />
              <article>
                <h3>Nurturing Independent Thinkers</h3>
                <p>
                  At RPMS, we follow the authentic Montessori approach,
                  empowering children to become independent thinkers and problem
                  solvers. Our program fosters a deep love for learning,
                  cultivates curiosity, and nurtures a sense of self-discipline.
                </p>
                <a href="/admissionform">
                  <Button value="Enroll Now" />
                </a>
                {/* I should but the carousel buttons */}
              </article>
            </article>
            <article className="horizontalCard toBeHidden">
              <img
                src={overviewImg}
                alt="A lady and two little girls staring at a drawing while one of the girls is doing the drawing"
              />
              <article>
                <h3>Nurturing Independent Thinkers</h3>
                <p>
                  At RPMS, we follow the authentic Montessori approach,
                  empowering children to become independent thinkers and problem
                  solvers. Our program fosters a deep love for learning,
                  cultivates curiosity, and nurtures a sense of self-discipline.
                </p>
                <a href="/admissionform">
                  <Button value="Enroll Now" />
                </a>
                {/* I should but the carousel buttons */}
              </article>
            </article>
            <i id="right" className="fa-solid fa-angle-right toBeHidden"></i>
          </div>
        </article>
      </article>
    </>
  );
};

// Gallery Section / Component
const Gallery = () => {
  return (
    <>
      <article className="montessoriMemoryMoments">
        <article className="rowOne">
          <article className="contentOne">
            <h3>Gallery</h3>
            <p>
              A Glimpse into the Vibrant and Engaging Experience of Our Students
            </p>
            <a href="/gallery">
              <Button value="See More" />
            </a>
          </article>
          <article className="contentTwo">
            <img
              src={momentoneImg}
              alt="A room with a green chalk board with various things on the shelf to the right of the board"
            />
          </article>
          <article className="contentThree toBeHidden">
            <img
              src={momenttwoImg}
              alt="A list of words"
              className="toBeHidden"
            />
          </article>
        </article>
        <article className="rowTwo toBeHidden">
          <article className="contentFour toBeHidden">
            <img src={momentThreeImg} alt="Little children in a race" />
          </article>
          <article className="contentFive toBeHidden">
            <img src={momentFourImg} alt="A boy playing with toys" />
          </article>
          <article className="contentSix toBeHidden">
            <img
              src={momentFiveImg}
              alt="An empty classroom"
              className="toBeHidden"
            />
          </article>
        </article>
      </article>
    </>
  );
};

// Calling all the section / components in their respective order
export default function Creche() {
  return (
    <>
      <Navbartrial />
      <IntroPage />
      <Curriculum />
      <InquirySection />
      <ProgramOverview />
      <Gallery />
      <Footer />
    </>
  );
}
