import React from "react";
import gallery1 from "../../assets/gallery1.png";
import gallery2 from "../../assets/gallery2.png";
import gallery3 from "../../assets/gallery3.png";
import gallery4 from "../../assets/gallery4.png";
import gallery5 from "../../assets/gallery5.png";

function GalleryNursery() {
  return (
    <section className="pics-gallery-wrapper">
      <img src={gallery1} className="gallery-content1" />
      <img src={gallery4} />
      <img src={gallery5} />
      <img src={gallery2} />
      <img src={gallery3} />
    </section>
  );
}

export default GalleryNursery;
