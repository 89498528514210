import React from "react";
import gallery1 from "../../assets/gallery1.png";
import gallery2 from "../../assets/gallery2.png";
import gallery7 from "../../assets/gallery7.png";
import gallery8 from "../../assets/gallery8.png";
import gallery9 from "../../assets/gallery9.png";

function GalleryOutdoors() {
  return (
    <section className="pics-gallery-wrapper">
      <img src={gallery8} />
      <img src={gallery7} />
      <img src={gallery1} className="gallery-content1" />
      <img src={gallery9} />
      <img src={gallery2} />
    </section>
  );
}

export default GalleryOutdoors;
