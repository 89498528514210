// Importing all resources
import "../home.css";
import "../responsive.css";
import "../about.css";
import circles from "../assets/cirlces.png";
import Footer from "../components/footer";
import mobileIntroImg from "../assets/Maskgroupabout.png";
import topImg from "../assets/Mask-group.png";
import puzzle from "../assets/puzzle-filled.svg";
import shield from "../assets/round-shield.svg";
import star from "../assets/round-star.svg";
import davidImg from "../assets/educatorDavid.png";
import emilyImg from "../assets/educatorEmily.png";
import alexImg from "../assets/educatorAlex.png";
import sarahImg from "../assets/educatorSarah.png";
import mission1 from "../assets/aboutmission1.png";
import mission1Mobile from "../assets/missionOneMobile.png";
import mission2Mobile from "../assets/missionTwoMobile.png";
import mission2 from "../assets/aboutmission2.png";
import mission3 from "../assets/aboutmission3.png";
import vision1 from "../assets/aboutvision1.png";
import vision2 from "../assets/aboutvision2.png";
import vision3 from "../assets/aboutvision3.png";
import vision1Mobile from "../assets/visionOneMobile.png";
import vision2Mobile from "../assets/visionTwoMobile.png";
import Navbartrial from "../components/navbartrial";
import Header from "../components/header";
import Carousel from "../components/carousel";

const teachersInfo = [
  {
    heading: "Mr. David",
    text: `Welcome Mr. David, our arts and creativity maestro. As a skilled artist and musician, he inspires young talents to express themselves...`,
    imageSrc: davidImg,
    imageAltText: "Mr. David",
    badgeOne: "Arts",
    badgeTwo: "Creativity Maestro",
  },
  {
    heading: "Ms. Emily",
    text: `Meet Ms. Emily, our dedicated English and Literature teacher. With a passion for language and storytelling, she instills a love for literatur...`,
    imageSrc: emilyImg,
    imageAltText: "Ms. Emily",
    badgeOne: "English",
    badgeTwo: "Literature",
  },
  {
    heading: "Mr. Alex",
    text: `Introducing Mr. Alex, our math whiz and problem-solving guru. With a talent for making complex concepts understandable, he brings...`,
    imageSrc: alexImg,
    imageAltText: "Mr. Alex",
    badgeOne: "Math Whiz",
    badgeTwo: "Problem-Solving Guru",
  },
  {
    heading: "Ms. Sarah",
    text: `Say hello to Ms. Sarah, our science explorer and nature enthusiast. With a background in environmental science, she ignites a passion f...`,
    imageSrc: sarahImg,
    imageAltText: "Ms. Sarah",
    badgeOne: "Science",
    badgeTwo: "Nature Enthusiast",
  },
  {
    heading: "Ms. Sarah",
    text: `Say hello to Ms. Sarah, our science explorer and nature enthusiast. With a background in environmental science, she ignites a passion f...`,
    imageSrc: sarahImg,
    imageAltText: "Ms. Sarah",
    badgeOne: "Science",
    badgeTwo: "Nature Enthusiast",
  },
  {
    heading: "Ms. Sarah",
    text: `Say hello to Ms. Sarah, our science explorer and nature enthusiast. With a background in environmental science, she ignites a passion f...`,
    imageSrc: sarahImg,
    imageAltText: "Ms. Sarah",
    badgeOne: "Science",
    badgeTwo: "Nature Enthusiast",
  },
];

// Inquiry Section / Component
const InquirySection = () => {
  return (
    <article className="inquirySection">
      <article className="content">
        <h3>Have a Message?</h3>
        <p>Send us your enquiry and we'll sort it out</p>
        <a href="/contact" className="pinkBtn">
          Get in Touch
        </a>
      </article>
    </article>
  );
};

// Simple Card Component
const SimpleCard = (props) => {
  const cardsArray = [
    {
      heading: "Excellence",
      imageSrc: star,
      imageAltText: "A leaf icon",
      text: "We strive for excellence in all aspects of education, empowering pupils to achieve their...",
    },
    {
      heading: "Integrity",
      imageSrc: shield,
      imageAltText: "A painting palette icon",
      text: "Integrity lies at the heart of our school community. We instill in our pupils the import...",
    },
    {
      heading: "Collaboration",
      imageSrc: puzzle,
      imageAltText: "The globe of the earth",
      text: "Collaboration is the key to success, and we cultivate a spirit of teamwork and cooperatio...",
    },
  ];
  const targetObject = cardsArray.find((item) => item.heading === props.value);
  return (
    <>
      <article className="simpleCard">
        <img src={targetObject.imageSrc} alt={targetObject.imageAltText} />
        <article className="content">
          <h3>{targetObject.heading}</h3>
          <p>{targetObject.text}</p>
          <br />
          <a href="#">Read More...</a>
        </article>
      </article>
    </>
  );
};

// Core Values Section / Component
const CoreValues = () => {
  return (
    <>
      <article className="curriculum">
        <h2>Our Core Values</h2>
        <p>
          Fostering Excellence, Integrity, Collaboration, Respect, and Growth in
          Education
        </p>
        <article className="simpleCards">
          <SimpleCard value="Excellence" />
          <SimpleCard value="Integrity" />
          <SimpleCard value="Collaboration" />
        </article>
      </article>
    </>
  );
};

// Calliing the various sections / components in their respective orders
export default function About() {
  return (
    <>
      <Navbartrial />
      <article className="introAbout">
        <Header
          title="About Us"
          text="Welcome to RPMS: Where Learning Knows No Boundaries. At RPMS, we
        believe that education is a journey of exploration and discovery"
          btnText="Schedule a Visit"
          linkUrl="/contact"
        />
        <article className="heroArticle ">
          <img src={topImg} alt="School picture" className="toBeHidden" />
          <img
            src={circles}
            alt="circles"
            className="circles-about toBeHidden"
          />
        </article>
        <div>
          <img src={mobileIntroImg} className="mobile-img" />
        </div>
      </article>

      <section className="about-mission">
        <div className="mission-gallery toBeHidden">
          <div className="mission-img-one">
            <img src={mission1} alt="" />
          </div>
          <div className="mission-img-two">
            <img src={mission2} alt="" />
          </div>
          <div className="mission-img-three">
            <img src={mission3} alt="" />
          </div>
        </div>
        <div className="mission-content">
          <h2>Our Mission</h2>
          <p>
            Our mission is to provide an exceptional learning experience that
            fosters intellectual curiosity, social-emotional development, and a
            sense of global citizenship. Through a blend of innovative teaching,
            character education, and engaging extracurriculars, we strive to
            shape well-rounded individuals ready to embrace challenges and
            opportunities.
          </p>
          <a href="/admissionform" className="primBtn">
            Enroll Now
          </a>
          <div>
            <img src={mission1Mobile} alt="" className="mobile-img" />
          </div>
          <div>
            <img src={mission2Mobile} alt="" className="mobile-img" />
          </div>
        </div>
      </section>

      <section className="about-vision">
        <div className="vision-content">
          <h2>Our Vision</h2>
          <p>
            Through collaboration with parents, educators, and the wider
            community, we seek to create a holistic educational experience that
            embraces cultural diversity, environmental stewardship, and social
            responsibility. Our vision is one where students graduate as
            well-rounded individuals, equipped not only with academic excellence
            but also with the values and character to lead meaningful and
            purposeful lives.
          </p>
          <a href="/admissionform" className="primBtn">
            Enroll Now
          </a>
          <div>
            <img src={vision1Mobile} alt="" className="mobile-img" />
          </div>
          <div>
            <img src={vision2Mobile} alt="" className="mobile-img" />
          </div>
        </div>
        <div className="vision-gallery toBeHidden">
          <div className="vision-img-one">
            <img src={vision1} alt="" />
          </div>
          <div className="vision-img-two">
            <img src={vision2} alt="" />
          </div>
          <div className="vision-img-three">
            <img src={vision3} alt="" />
          </div>
        </div>
      </section>
      <InquirySection />
      <CoreValues />
      <Carousel data={teachersInfo} />
      <Footer />
    </>
  );
}
