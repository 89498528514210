import "../header.css";
import { Link } from "react-router-dom";
import Button from "../components/button";

function Header(props) {
  return (
    <>
      <div className="header-component">
        {props.title && <h1>{props.title}</h1>}
        {props.subtitle && <h2>{props.subtitle}</h2>}
        <p>{props.text}</p>
        {props.btnText && (
          <a href={props.linkUrl} className="btn-header">
            {props.btnText}
          </a>
        )}
      </div>
    </>
  );
}

export default Header;
