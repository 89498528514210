import logo from "../assets/logo.svg";
import burger from "../assets/burger.svg";
import Button from "./button";
import { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import "../navbartrial.css";
import DropdownMenu from "./dropdown";

function Navbartrial() {
  const [showNavbar, setShowNavbar] = useState(false);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  const handleClosing = () => {
    setShowNavbar(false);
    console.log("close");
  };

  useEffect(() => {
    if (typeof window !== "undefined" && window.document) {
      if (showNavbar) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showNavbar]);

  return (
    <div className="navbar-container">
      <div className="navbar-trial">
        <div className="logo-nav">
          <Link to="/home">
            <img src={logo} alt="RPMC's logo" />
          </Link>
        </div>
        <div className="burger-icon-nav" onClick={handleShowNavbar}>
          {(showNavbar && <i class="fa-solid fa-xmark fa-2xl"></i>) || (
            <img src={burger} />
          )}
        </div>
        <div className={`navtrial-links ${showNavbar && "active-navlinks"}`}>
          <ul>
            <li>
              <NavLink to="/home" onClick={handleClosing}>
                Home
              </NavLink>
            </li>
            <li>
              <div className="dropdown-container">
                {/* <p>Program</p> */}
                <DropdownMenu />
              </div>
            </li>
            <li>
              <NavLink to="/facilities" onClick={handleClosing}>
                Facilities
              </NavLink>
            </li>
            <li>
              <NavLink to="/gallery" onClick={handleClosing}>
                Gallery
              </NavLink>
            </li>
            <li>
              <NavLink to="/contact" onClick={handleClosing}>
                Contact
              </NavLink>
            </li>
            <li>
              <div className="btnHide">
                <a href="https://pupils.rulerspalacemontessori.com.ng/login">
                  <Button value="Login" />
                </a>
              </div>
            </li>
          </ul>
        </div>
        <div className="btntoHide">
          <a href="https://pupils.rulerspalacemontessori.com.ng/login">
            <Button value="Login" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Navbartrial;
