import logo from "../assets/logo.svg";
import facebookImg from "../assets/facebook.png";
import instagramImg from "../assets/instagram.png";
import linkedinImg from "../assets/linkedin.png";
import "../footer.css";
import { Link } from "react-router-dom";

export default function Footer() {

  const d = new Date();
  let year = d.getFullYear();

  return (
    <>
      <section className="footer-container">
        <div className="principal-content">
          <div className="logo-footer toBeHidden">
            <img src={logo} alt="A blue crown" />
          </div>
          <div className="links-footer">
            <section className="contact-footer">
              <p className="section-title-footer">Contact</p>
              <a href="https://maps.app.goo.gl/SgCQqZBAAihK3vjj9">
                Address: 1 Jesus Is Lord Street Igbogbobayeku, Ikorodu, Lagos,
                Nigeria
              </a>
            </section>

            <div>
              <section className="quickLinks-footer">
                <p className="section-title-footer">Quick Links</p>
                <a href="/admission">Admission</a>
                <a href="/about">About Us</a>
                <a href="/news">News</a>
                <a href="#">Terms of Use</a>
                <a href="#">Privacy Policy</a>
              </section>
              <div className="socialmedia-footer">
                <p className="section-title-footer">Socials</p>
                <Link to="https://www.facebook.com/rpmsch">
                  <img src={facebookImg} alt="Facebook Logo" />
                </Link>
                <Link to="https://www.instagram.com/rulerspalacemontessorischool">
                  <img src={instagramImg} alt="Instagram Logo" />
                </Link>
                <Link to="#">
                  <img src={linkedinImg} alt="Linkedin Logo" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <p className="rights-text">© {year}  RPMS. All rights reserved.<a className="mpcLink" href=" " ><i> Powered by MPC </i></a></p>
      </section>
    </>
  );
}
