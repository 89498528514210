import React from "react";
import Badge from "./badge";
import emilyImg from "../assets/educatorEmily.png";
import "../singleSliderCard.css";

export default function SingleSliderCard({ item }) {
  console.log(item);
  return (
    <div className="slider-card-container">
      <img src={item.imageSrc} />
      <div>
        <h3>{item.heading}</h3>
        <p>{item.text}</p>
        <section>
          <Badge value={item.badgeOne} />
          <Badge value={item.badgeTwo} />
        </section>
        <a>Read More...</a>
      </div>
    </div>
  );
}
