import React from "react";
import news1 from "../assets/newspage1.png";
import "../singleNew.css";

const newInfo = {
  image: news1,
  category: "Event",
  title: "Exploring the Wonders of Science",
  date: "Thursday 21 September 2023 19:11, UK",
  subtitle:
    " Our students had a fantastic day of learning and fun at the local science museum. From interactive exhibits to hands-on experiments, they immersed themselves in the world of science and discovery.",
  text: "Our students had a fantastic day of learning and fun at the local science museum. From interactive exhibits to hands-on experiments, they immersed themselves in the world of science and discovery. Our students had a fantastic day of learning and fun at the local science museum. From interactive exhibits to hands-on experiments, they immersed themselves in the world of science and discovery. Our students had a fantastic day of learning and fun at the local science museum. From interactive exhibits to hands-on experiments, they immersed themselves in the world of science and discovery. Our students had a fantastic day of learning and fun at the local science museum. From interactive exhibits to hands-on experiments, they immersed themselves in the world of science and discovery. Our students had a fantastic day of learning and fun at the local science museum. From interactive exhibits to hands-on experiments, they immersed themselves in the world of science and discovery. Our students had a fantastic day of learning and fun at the local science museum. From interactive exhibits to hands-on experiments, they immersed themselves in the world of science and discovery. Our students had a fantastic day of learning and fun at the local science museum. From interactive exhibits to hands-on experiments, they immersed themselves in the world of science and discovery. Our students had a fantastic day of learning and fun at the local science museum. From interactive exhibits to hands-on experiments, they immersed themselves in the world of science and discovery. Our students had a fantastic day of learning and fun at the local science museum. From interactive exhibits to hands-on experiments, they immersed themselves in the world of science and discovery.",
};

const SingleNew = () => {
  return (
    <div className="singleNew-container">
      <h1>{newInfo.title}</h1>
      <h3>{newInfo.subtitle}</h3>
      <p className="new-date">{newInfo.date}</p>
      <div className="new-content">
        <img src={newInfo.image} />
        <p className="new-text">{newInfo.text}</p>
      </div>
    </div>
  );
};

export default SingleNew;
