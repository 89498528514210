import Footer from "../components/footer";
import Navbartrial from "../components/navbartrial";
import "../contact.css";
import formImg from "../assets/contactformimg.png";

export default function Contact() {
  return (
    <div className="contact-container">
      <Navbartrial />
      <div className="form-header">
        <h2>Get in Touch!</h2>
        <p>How Can We Help?</p>
      </div>
      <div className="contact-form-container">
        <div className="pink-cover"></div>
        <img src={formImg} className="toBeHidden" />
        <form>
          <div className="input-data">
            <label>Name of Child</label>
            <input type="text" placeholder="e.g John" />
          </div>
          <div className="input-data">
            <label>Name of Child</label>
            <input type="text" placeholder="e.g John" />
          </div>
          <div className="input-data">
            <label>Name of Child</label>
            <input type="text" placeholder="e.g John" />
          </div>
          <div className="textarea">
            <label>Name of Child</label>
            <textarea placeholder="e.g John" />
          </div>
          <button>Submit</button>
        </form>
      </div>
      <Footer />
    </div>
  );
}
